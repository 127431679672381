(function () {
    'use strict';

    /**
     ToolsDefinitionService factory
     STATELESS and IMMUTABLE, please leave like that
     produces models prototyped with delete/update functionality.
     */
    angular.module('aerosApp').factory('ToolsDefinitionService', ToolsDefinitionService);

    ToolsDefinitionService.$inject = ['aerosApi', '$q', '$http'];
    function ToolsDefinitionService(aerosApi, $q, $http) {

        return {
            fetch: getToolsDefinition,
            format: {
                nested2flatExcept: nested2flatExcept,
                modelizeListWith: modelizeListWith,
                modelizeItem: modelizeItem,
                transformValues: transformValues,
                flat2nested: flat2nested,
                nested2flat: nested2flat,
                addJsonPrefixes: addJsonPrefixes,
                removeJsonPrefixes: removeJsonPrefixes,
                objToArr: objToArr
            },
            findDescriptionByName: findDescriptionByName,
            findChildren: findChildren,
            findNearest: findNearest,
            updateModelWithNearestValue: updateModelWithNearestValue,
            isVisible: isVisible
        };

        function modelizeListWith(ModelPrototype) {
            return function (list) {
                return _.map(list, modelizeItem(ModelPrototype));
            }
        }

        function modelizeItem(ModelPrototype, props) {
            return function (item) {
                return _.extend(Object.create(ModelPrototype, {
                    id: {value: item.uuid},
                    orgId: {value: item.organizationId}
                }), item, props);
            };
        }

        function removeJsonPrefixes(item) {
            return _.mapKeys(item, function (v, k) {
                return k.replace('json', '');
            });
        }

        function addJsonPrefixes(model) {
            return Object.keys(model).reduce(function (jsonModel, element) {
                if (angular.isObject(model[element])) {
                    jsonModel['json' + element] = model[element];
                } else {
                    jsonModel[element] = model[element];
                }
                return jsonModel;
            }, {});
        }

        function transformValues(transformer, obj) {
            return _.mapValues(obj, function (val, key) {
                return _.isObject(val) ? transformer(val) : val;
            });
        }

        function getToolsDefinition() {
            return $http.get('/static/templates/tools/toolsDefinition.json', {cached: true}).then(function (res) {
                return res.data
            });
        }

        function flat2nested(model) {

            return Object.keys(model).reduce(function (acc, field) {
                var value = acc[field];
                delete acc[field];
                return dotNotatedToNested(acc, field, value);
            }, model);

            function dotNotatedToNested(model, field, value) {
                field.split('.').reduce(function (acc, element, index, arr) {
                    if (!acc[element] && index < arr.length - 1) {
                        acc[element] = {};
                    }

                    return index < arr.length - 1 ? acc[element] : acc[element] = value;
                }, model);

                return model;
            }

        }

        function nested2flatExcept(exceptions) {
            return function (obj) {
                return _.assign(
                    transformValues(nested2flat, _.pick(obj, exceptions)), // some keys should remain nested on top-level,
                    nested2flat(_.omit(obj, exceptions))                   // while others become flatten
                );
            }
        }

        function arrToObj(data) {
            var obj = {};
            data.wavelengths.map(function (key) {
                return obj[key] = true;
            });
            return obj;
        }

        function objToArr(obj) {
            var vavelengthObj;

            if (obj['wavelengths'] && angular.isObject(obj['wavelengths']) && !angular.isArray(obj['wavelengths'])) {
                vavelengthObj = Object.keys(obj['wavelengths']).filter(function (key) {
                    if (obj['wavelengths'][key]) return key;
                })
                obj['wavelengths'] = vavelengthObj;
            }
            return obj
        }

        function nested2flat(model) {
            if (model.wavelengths instanceof Array) {
                model.wavelengths = arrToObj(model)
            }

            var flatModel = {},
                countObjs = 0;

            Object.keys(model).reduce(function (acc, element) {
                if (angular.isObject(acc[element]) && Object.keys(acc[element]).length > 0 && element !== "wavelengths") {
                    Object.keys(acc[element]).reduce(function (accElement, item) {
                        flatModel[element + '.' + item] = acc[element][item];

                        if (angular.isObject(acc[element][item])) {
                            countObjs++;
                        }

                        return flatModel;
                    }, flatModel);
                } else {
                    flatModel[element] = acc[element];
                }
                return acc;
            }, model);

            return countObjs === 0 ? flatModel : nested2flat(flatModel);
        }

        function isFieldInToolDefinitionsGroup(toolDefinition, key) {
            return toolDefinition.groups.map(function (group) {
                    return group.id;
                }).indexOf(key) >= 0;
        }

        function pack(args, value) {
            var args = args.slice(0),
                obj = {}, arg = args.splice(0, 1)[0];

            obj[arg] = args.length > 0 ?
                pack(args, value) : _.isObject(value) ?
                    flat2nested(value) : undefined;

            return obj[arg] ? obj : (obj[arg] = value, obj);
        }

        function unpack(parentKey, value) {
            return _.isObject(value) ?
                _.keys(value).map(function (key) {
                    return unpack([parentKey, key].join('.'), value[key]);
                }) :
                _.zipObject([parentKey], [value]);
        }

        function findDescriptionByName(dotNotationName, toolDefinition) {

            for (var i in toolDefinition.properties) {
                var item = toolDefinition.properties[i];
                if (dotNotationName == item.fieldName) {
                    return angular.extend(item);
                }
            }

            for (var g in toolDefinition.groups) {
                var group = toolDefinition.groups[g];

                for (i in group.fields) {
                    item = group.fields[i];
                    if (dotNotationName == group.id + "." + item.fieldName) {
                        return angular.extend(item, {group: group});
                    }
                }
            }
        }

        function findChildren(dotNotationName, toolDefinition) {
            var children = [];

            angular.forEach(toolDefinition.properties, function (item) {
                if (item.fieldDescription.parent && item.fieldDescription.parent === dotNotationName) {
                    children.push(item);
                }
            });

            return children;
        }

        function findNearest(list, value) {
            var nearest;

            if (list.length > 0) {
                nearest = list[0];
                for (var i in list) {
                    if (Math.abs(list[i].value  - value) < Math.abs(nearest.value - value)) {
                        nearest = list[i];
                    }
                }
            }

            return nearest;
        }

        function updateModelWithNearestValue(child, fieldName, toolModel, fieldDescription) {

            if (!toolModel[fieldName]
                && fieldDescription.required) {
                toolModel[fieldName] = fieldDescription.default;
            }

            var range = child.fieldDescription.values[toolModel[fieldName]];

            range = Array.isArray(range.values) ? range.values
                : range.values[toolModel.fiberMode].values;

            if (toolModel[child.fieldName] && toolModel[child.fieldName].value != 0) {
                range = range.filter(function (item) {
                    return item.value != 0;
                })
            }

            var nearest = findNearest(range, toolModel[child.fieldName]);

            toolModel[child.fieldName] = nearest.value;
        }

        function isVisible(description, model) {
            return description['type'] === 'hidden' ? false : !description.parent ||
                (description.parent && description.values[model[description.parent]]);
        }

    }

}());
